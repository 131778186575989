/**
 * CandidateService
 * Handles fetching data related to candidate
 * */
import { sget, sput, spost, sdelete } from "./io"
import { isLateralMode } from "utils/productUtils"
import {
  isCRSAOOrganization,
  isSubmittedApplication,
  isHREnabled,
} from "utils/utils"
import { getFormConfigurationFromCandidate } from "utils/configUtils"

const url = "/candidate"

/**
 * Load candidate profile
 * @returns {AxiosPromise}
 */
export const getCandidate = candidateId => {
  return sget({
    url: `${url}/${candidateId}`,
  })
}

export const updateCandidateDocumentType = (
  organization,
  candidateId,
  contactHistoryId,
  documentType
) => {
  return spost({
    url: `/organization/${organization.id}/candidate/${candidateId}/documentType/${contactHistoryId}/${documentType}`,
  })
}

/**
 * Load candidate profile by id
 * @param id
 * @returns {AxiosPromise}
 */
export const getCandidateById = (organization, id) => {
  return sget({
    url: `/organization/${organization.id}/candidate/${id}`,
  })
}

const shouldUpdate = (resource, maxSteps, organization, forceOverride) => {
  if (resource.step + 1 === maxSteps) {
    return true
  }
  if (
    resource.cv_base64 ||
    resource.cover_base64 ||
    resource.photo_base64 ||
    isSubmittedApplication(resource)
  ) {
    return true
  }
  if (forceOverride) {
    return true
  }
  const configuration = getFormConfigurationFromCandidate(resource)
  if (
    !isLateralMode() &&
    ((isCRSAOOrganization(organization) &&
      isHREnabled(configuration, "doNotSave")) ||
      (organization && organization.overload))
  ) {
    return false
  }
  return true
}

const removeRoleAndOrganizationObjects = candidate => {
  const result = {
    ...candidate,
  }
  if (result.role) {
    result.role = {
      id: result.role.id,
    }
  }
  if (result.organization) {
    result.organization = {
      id: result.organization.id,
    }
  }
  return result
}

/**
 * Update candidate profile
 * @param resource
 * @returns {AxiosPromise}
 */
export const updateCandidate = (
  resource,
  maxSteps,
  organization,
  forceOverride
) => {
  if (shouldUpdate(resource, maxSteps, organization, forceOverride)) {
    return sput({
      url: `${url}/${resource.id}${maxSteps ? `?maxSteps=${maxSteps}` : ""}`,
      data: removeRoleAndOrganizationObjects(resource),
    })
  } else {
    return new Promise((resolve, reject) => {
      if (resource.candidateStatus !== "ACTIVE") {
        if (!maxSteps || resource.step < maxSteps) {
          resource.step = resource.step + 1
        }
      }
      resolve({
        data: resource,
      })
    })
  }
}

/**
 * Update candidate profile
 * @param resource
 * @returns {AxiosPromise}
 */
export const updateCandidateUsingCV = resource => {
  return sput({
    url: `${url}/${resource.id}/cv`,
    data: removeRoleAndOrganizationObjects(resource),
  })
}

/**
 * Change the candidate profile step
 * @returns {AxiosPromise}
 */
export const nextStep = (id, maxSteps) => {
  return sput({
    url: `${url}/${id}/nextStep${maxSteps ? `?maxSteps=${maxSteps}` : ""}`,
  })
}

/**
 * Publish profile
 * @returns {AxiosPromise}
 */
export const publish = id => {
  return sput({
    url: `${url}/${id}/submit`,
  })
}

/**
 * Get Organization
 * @returns {AxiosPromise}
 */
export const getOrganization = id => {
  return sget({
    url: `${url}/${id}/organization`,
  })
}

/**
 * Get Organization
 * @returns {AxiosPromise}
 */
export const updateStage = (id, stage, substage, organizationId, data = {}) => {
  return spost({
    url: `${url}/${id}/stage/${stage.id}/${substage.id}/organization/${organizationId}`,
    data,
  })
}

export const updateOrganizationStage = (id, stage) => {
  return spost({
    url: `${url}/${id}/ostage/${stage.id}`,
  })
}

export const deleteCandidate = id => {
  return sdelete({
    url: `${url}/${id}`,
  })
}

export const undoCandidate = id => {
  return spost({
    url: `${url}/${id}/undo`,
  })
}

export const migrateToCandidX = id => {
  return spost({
    url: `${url}/${id}/migratex`,
  })
}

export const getCurrentInterview = (organizationId, id) => {
  return sget({
    url: `${url}/${organizationId}/${id}/getCurrentInterview`,
  })
}

/**
 * Get Organization
 * @returns {AxiosPromise}
 */
export const updateStageBulk = (
  resource,
  ids,
  stage,
  stageId,
  substageId,
  roleId,
  options
) => {
  return spost({
    url: `${url}/bulkstage/${stage.stage.id}/${stage.id}`,
    params: { assignee: true },
    data: {
      ids,
      substageId,
      stageId,
      roleId,
      resource,
      ...options,
    },
  })
}

export const fastTrackCandidates = (
  organization,
  candidates,
  role,
  stage,
  async
) => {
  return spost({
    url: `${url}/${organization}/fasttrackcandidates/${role.id}/stage/${stage.id}`,
    data: { ids: candidates, async },
  })
}

export const copyFromVantage = (id, copy) => {
  return spost({
    url: `${url}/${id}/copy/${copy}`,
  })
}

export const updateAssigned = (id, assigneeId) => {
  return spost({
    url: `${url}/${id}/assignee/${assigneeId}`,
  })
}

export const addEvent = (id, eventId) => {
  return spost({
    url: `${url}/${id}/events/${eventId}`,
  })
}

export const addApplication = (id, roleId, stage, dateApplied) => {
  return spost({
    url: `${url}/${id}/applications/${roleId}/stage/${stage.id}`,
    data: {
      dateApplied,
    },
  })
}

export const addSession = (id, data) => {
  return spost({
    url: `${url}/${id}/sessions`,
    data,
  })
}

export const updateSession = (candidateId, data, sessionId) => {
  return spost({
    url: `${url}/${candidateId}/session/${sessionId}`,
    data,
  })
}

export const uploadCvOrCL = (id, documentType, data) => {
  return spost({
    url: `${url}/${id}/upload/${documentType}`,
    data,
  })
}

export const generateDownloadLink = (id, filename) => {
  return spost({
    url: `${url}/${id}/download`,
    data: {
      filename,
    },
  })
}

export const loadPhoto = id => {
  return sget({
    url: `${url}/${id}/downloadPhoto`,
    responseType: "blob",
  })
}

export const getCandidateContactById = (organization, id, type, page = 0) => {
  return sget({
    url: `/organization/${organization.id}/candidate/${id}/contact/${type}`,
    params: {
      page,
    },
  })
}

export const uploadCandidateDocument = (organization, id, data) => {
  return spost({
    url: `/organization/${organization.id}/candidate/${id}/upload`,
    data,
  })
}

export const removeCandidateDocument = (organization, id, fileId) => {
  return sdelete({
    url: `/organization/${organization.id}/candidate/${id}/upload/${fileId}`,
  })
}

export const downloadCandidateDocument = (organization, id, contact) => {
  return spost({
    url: `/organization/${organization.id}/candidate/${id}/download/${contact.id}`,
  })
}

// the returned url can be used to download the document
export const getAdditionalDocumentDownloadUrl = (organization, id, contact) => {
  return sget({
    url: `/organization/${organization.id}/candidate/${id}/download/${contact.id}`,
  })
}

export const saveAdjusted = (organization, id, adjustment) => {
  return sput({
    url: `/organization/${organization.id}/candidate/${id}/adjustment`,
    data: {
      adjustment,
    },
  })
}

export const submitDocumentRequest = (organizationId, request, options) => {
  return spost({
    url: `${url}/${organizationId}/docusign/`,
    data: {
      ...options,
      ...request,
    },
  })
}

export const submitExportRequest = (organizationId, request, options) => {
  return spost({
    url: `${url}/${organizationId}/export/`,
    data: {
      ...options,
      ...request,
    },
  })
}
